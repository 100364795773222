<template>
    <div class="forgot__container">
        <base-dialog :show="showDialog" :title="dialogTitle" :text="dialogText">
            <template #actions>
                <v-btn :color="dialogButtonColor" @click="closeDialog()">
                    {{ $t("General.ok") }}
                </v-btn>
            </template>
        </base-dialog>
        <v-card class="forgot_form__container" max-width="800" height="500">
            <v-img
                src="../../assets/logoagro.png"
                max-height="100"
                max-width="100"
                alt="agrobofood logo"
                class="logo"
            >
            </v-img>
            <h4 class="text-center px-3">
                <p class="text-body-2 pa-2 mb-3">
                    {{ $t("ForgotPass.cardTitle") }}
                </p>
            </h4>
            <v-form class="forgot_form" @submit.prevent="submitEmail()">
                <v-card-text>
                    <v-text-field
                        v-model="email"
                        :error-messages="emailErrors"
                        :label="$t('Login.fields.email')"
                        required
                        outlined
                        @input="$v.email.$touch()"
                        @blur="$v.email.$touch()"
                    ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        depressed
                        type="submit"
                        class="submit-button-hover"
                        :disabled="formIsValid"
                        :loading="forgotPasswordStatus_Pending"
                    >
                        {{ $t("General.submit") }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </div>
</template>

<script>
// API layer Imports
import { withAsync } from "../../helpers/withAsync";
import { apiStatus } from "../../api/constants/apiStatus.js";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus.js";
import { forgotPassword } from "../../api/authApi.js";

// Vuelidate Imports
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

// Component Imports
import BaseDialog from "../../components/base/BaseDialog.vue";

export default {
    name: "ForgotPassword",

    mixins: [validationMixin],

    validations: {
        email: {
            required,
            email,
        },
    },

    components: {
        BaseDialog,
    },

    data() {
        return {
            email: "",
            forgotPasswordStatus: apiStatus.Idle,
            showDialog: false,
            dialogSuccess: false,
            dialogTitle: "",
            dialogText: "",
        };
    },

    computed: {
        ...apiStatusComputed("forgotPasswordStatus"),

        /**
         * Computed function used to validate inputs with vuelidate.
         * @return {errors} return errors that are shown on blur and on input
         */

        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email &&
                errors.push(this.$t("ErrorMessages.validEmail"));
            !this.$v.email.required &&
                errors.push(this.$t("ErrorMessages.required"));
            return errors;
        },

        formIsValid() {
            return this.$v.$invalid;
        },

        dialogButtonColor() {
            return this.dialogSuccess ? "primary" : "error";
        },
    },

    methods: {
        /**
         * Sends a call to the backend holding the needed data as payload. Returns success or unsuccessful
         * dialog based on backend response for email sent or not sent.
         */
        async submitEmail() {
            this.forgotPasswordStatus = apiStatus.Pending;
            const payload = {
                email: this.email,
            };

            const { response, error } = await withAsync(
                forgotPassword,
                payload
            );

            if (error) {
                this.forgotPasswordStatus = apiStatus.Error;
                this.dialogTitle = this.$t("ForgotPass.errorDialogTitle");
                this.dialogText = `${error.response.data} ${this.$t(
                    "ForgotPass.errorDialogText"
                )}`;
                this.dialogSuccess = false;
                this.showDialog = true;
                return;
            }
            this.forgotPasswordStatus = apiStatus.Success;

            this.dialogTitle = this.$t("ForgotPass.successDialogTitle");
            this.dialogText = this.$t("ForgotPass.successDialogText");
            this.dialogSuccess = true;
            this.showDialog = true;
        },

        /**
         * Closes the dialog. If the dialog was successful => the action was successful
         * redirects the user to login page
         */

        closeDialog() {
            this.showDialog = false;
            if (this.dialogSuccess) {
                this.$router.push("/login");
                return;
            }
        },
    },

    created() {
        this.apiStatus = apiStatus;
    },
};
</script>

<style scoped>
.forgot__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    background-image: linear-gradient(60deg, #42855b 0%, #e8e6d3 100%);
    background-size: 400% 400%;
    opacity: 0.8;
    animation: animateBackground 10s ease infinite;
}

.forgot__container::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: -2 !important;
    background: url("../../assets/pattern-circles.svg");
}
.logo {
    transform: translate(-50px, -50px);
}
.forgot_form__container {
    width: 80%;
    height: 30%;
    margin: 50px;
}

.forgot_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.submit-button-hover:hover {
    background: #C35272 !important;
    transition: 0.1s ease-in;
}

@keyframes animateBackground {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
</style>
